<template>
  <div class="pdf-content">
    <vue-pdf-app 
      :pdf="source" 
      theme="light" 
      class="pdfTemp" 
      :config="config"
      pageScale="auto"
      :page-number="1"
    ></vue-pdf-app>
    <v-btn 
      color="primary"
      :min-width="120"
      class="mt-4"
      large
      :disabled="!url"
      @click="fillForm()"
    >
      CETAK
    </v-btn>
  </div>
</template>

<script>
import { get } from 'dot-prop'
import moment from 'moment'
import { PDFDocument } from 'pdf-lib'
import downloadjs from "downloadjs"
import { convert } from 'html-to-text'
import "vue-pdf-app/dist/icons/main.css"
import VuePdfApp from "vue-pdf-app"
import { sth, sert, mdiv, mmin, mth, mthu } from '@/constants/pdfContent'
import request from '@/utils/request'
export default {
  data () {
    return {
      source: '',
      config: {
        toolbar: {
          toolbarViewerRight: {
            presentationMode: false,
            openFile: false,
            print: false,
            download: false,
            viewBookmark: false,
          },
          toolbarViewerLeft: { 
            findbar: false,
            previous: true,
            next: true,
            pageNumber: true,
          }
        },
      },
      url: '',
      formData: [],
    }
  },
  components: {
    VuePdfApp
  },
  computed: {
    recommendationResult () {
      return get(this.$store.state, 'recommendation_form_result', [])
    },
    getCompletionForm () {
      const data = get(this.$store.state, 'completionForm', {})
      data.is_recommendation_form_result = false
      let isRecommendationFormInvalid = this.recommendationResult.some(itm => !itm.question_1)
      if (!isRecommendationFormInvalid && this.recommendationResult.length > 0) {
        data.is_recommendation_form_result = true
      }
      return data
    },
    getPaymentData () {
      return get(this.$store.state, 'payment_form', {})
    },
    getPersonalData () {
      return get(this.$store.state, 'registrationForm.personal_data', {})
    },
    getFamilyData () {
      const data = get(this.$store.state.registrationForm, 'family_data', [])
      const famData = {}
      famData.birth_order = `${data.birth_order}`
      famData.total_sibling = `${data.total_sibling}`
      data.family_member && data.family_member.map((itm, idx) => {
        famData[`role-${idx+1}`] = itm.role
        famData[`name-${idx+1}`] = itm.name
        famData[`phone-${idx+1}`] = itm.phone
        famData[`job-${idx+1}`] = itm.job
        famData[`dob-${idx+1}`] = itm.birth_date > 0 ? moment(new Date(itm.birth_date*1000)).locale('id').format('DD MMMM YYYY') : '-'
      })
      return famData
    },
    getMarriageData () {
      const data = get(this.$store.state.registrationForm, 'marriage_data', {})
      const marData = {...data}
      marData.dob = ''
      if (data.birth_date > 0) {
        marData.dob = `${data.birth_place}, ${moment(new Date(data.birth_date*1000)).locale('id').format('DD MMMM YYYY')}`
      }
      if (data.family_core_member) {
        data.family_core_member.map((itm, idx) => {
          marData[`name-anak-${idx+1}`] = itm.name
          marData[`dob-anak-${idx+1}`] = ''
          if (itm.birth_date > 0) {
            marData[`dob-anak-${idx+1}`] = `${itm.birth_place}, ${moment(new Date(itm.birth_date*1000)).locale('id').format('DD MMMM YYYY')}`
          }
          marData[`edu-anak-${idx+1}`] = itm.last_education
        })
      }
      return marData
    },
    getHealthData () {
      return get(this.$store.state, 'registrationForm.health_history_data', {})
    },
    getSkillData () {
      const data = get(this.$store.state, 'registrationForm.skill_data', {})
      const skillData = {}
      data && data.map((itm, idx) => {
        skillData[`name-${idx+1}`] = itm.name
        skillData[`level-${idx+1}`] = itm.level
      })
      return skillData
    },
    getEducationData () {
      const data = get(this.$store.state, 'registrationForm.education_history_data', {})
      const eduData = {}
      data && data.map((itm, idx) => {
        eduData[`school_name-${itm.attr}`] = itm.school_name
        eduData[`city-${itm.attr}`] = itm.city
        eduData[`year-${itm.attr}`] = itm.graduation_year
        if (itm.education_level) {
          eduData[`education_level-${itm.attr}`] = itm.education_level
        }
      })
      return eduData
    },
    getUnivData () {
      const data = get(this.$store.state, 'registrationForm.education_history_data', {})
      const eduData = {}
      data && data.map((itm, idx) => {
        eduData[`college-${itm.attr}`] = itm.college
        eduData[`major-${itm.attr}`] = itm.major_study
        eduData[`year-${itm.attr}`] = itm.graduation_year
        eduData[`ipk-${itm.attr}`] = `${itm.ipk}`
      })
      return eduData
    },
    getJobData () {
      const data = get(this.$store.state, 'registrationForm.job_history_data', {})
      const jobData = {}
      data && data.map((itm, idx) => {
        jobData[`place-${itm.attr}`] = itm.place
        jobData[`position-${itm.attr}`] = itm.position
        jobData[`start-${itm.attr}`] = itm.period_start
        jobData[`end-${itm.attr}`] = itm.period_end 
      })
      return jobData
    },
    getServiceData () {
      const data = get(this.$store.state, 'registrationForm.service_history_data', {})
      const serviceData = {}
      data && data.map((itm, idx) => {
        serviceData[`church-${itm.attr}`] = itm.church
        serviceData[`position-${itm.attr}`] = itm.position
        serviceData[`start-${itm.attr}`] = itm.period_start
        serviceData[`end-${itm.attr}`] = itm.period_end
      })
      return serviceData
    },
    getChurchData () {
      return get(this.$store.state, 'registrationForm.church_member_data', {})
    },
    getFinancialData () {
      return get(this.$store.state, 'registrationForm.financial_data', {})
    },
    getPersonalSpiritualData () {
      return get(this.$store.state, 'registrationForm.personal_spiritual_data', {})
    },
    getPersonalStrugleData () {
      const personalData = get(this.$store.state, 'registrationForm.personal_struggle_data', {})
      const data = {...personalData, ...personalData.addiction}
      return data
    },
    getOtherData () {
      const otherData = get(this.$store.state, 'registrationForm.other_data', {})
      const data = {...otherData, ...otherData.heard_from_where}
      return data
    },
    getAgreementData () {
      return get(this.$store.state, 'registrationForm.agreement_data', {})
    },
    getCompletenessData () {
      return get(this.$store.state, 'completeness_data_form', {})
    },
    getConcentrationData () {
      return get(this.$store.state, 'registrationForm.concentration', '')
    },
    getRecommendationData () {
      return get(this.$store.state, 'recommendation_form', '')
    },
    getRepetance () {
      const data = get(this.$store.state, 'repentance_story', '')
      const text = convert(data, {
        wordwrap: 130
      })
      return data ? text : ''
    },
    getMotivationStory () {
      const data = get(this.$store.state, 'motivation_story', '')
      const text = convert(data, {
        wordwrap: 130
      })
      return data ? text : ''
    },
    getCallingFaithStory () {
      const data = get(this.$store.state, 'calling_faith_story', '')
       const text = convert(data, {
        wordwrap: 130
      })
      return data ? text : ''
    }
  },
  created () {
    this.getConfigData()
    this.source = this.url
  },
  async mounted () {
    const formUrl = this.url
    if (formUrl) {
      const formPdfBytes = await fetch(formUrl).then(res => res.arrayBuffer())
      const pdfDoc = await PDFDocument.load(formPdfBytes)
      if (this.getCompletionForm.is_registration_form) {
  
        const selfPictUrl = this.getCompletenessData.self_pict_url || ''
        const selfPictBytes = await fetch(selfPictUrl).then(res => res.arrayBuffer())

        const signPictUrl = this.getAgreementData.sign_pict_url || ''
        const signPictBytes = await fetch(signPictUrl).then(res => res.arrayBuffer())

        const guardianSignPictUrl = this.getAgreementData.guardian_sign_pict_url || ''
        const guardianSignPictBytes = await fetch(guardianSignPictUrl).then(res => res.arrayBuffer())

        let embededSelfPict = ''
        let embededSignPict = ''
        let embededGuardianSign = ''

        try {
          embededSelfPict = selfPictUrl ? await pdfDoc.embedPng(selfPictBytes) : ''
        } catch (error) {
          embededSelfPict = selfPictUrl ? await pdfDoc.embedJpg(selfPictBytes) : ''
        }
        try {
          embededSignPict = signPictUrl ? await pdfDoc.embedPng(signPictBytes) : ''
        } catch (error) {
          embededSignPict = signPictUrl ? await pdfDoc.embedJpg(signPictBytes) : ''
        }
        try {
          embededGuardianSign = guardianSignPictUrl ? await pdfDoc.embedPng(guardianSignPictBytes) : ''
        } catch (error) {
          embededGuardianSign = guardianSignPictUrl ? await pdfDoc.embedJpg(guardianSignPictBytes) : ''
        }

        const pictImage = {
          self_pict_url: embededSelfPict,
          sign_pict_url: embededSignPict,
          guardian_sign_pict_url: embededGuardianSign,
        }
    
        const form = pdfDoc.getForm()
        // const fields = form.getFields()
        // fields.forEach(field => {
        //   const type = field.constructor.name
        //   const name = field.getName()
        //   if (type === 'PDFTextField') {
        //     console.log(`${type}: ${name}`)
        //   } else if (type === 'PDFRadioGroup') {
        //     console.log(`${type}: ${name}`)
        //     console.log(form.getRadioGroup(field.getName()).getOptions())
        //   } else {
        //     console.log(`${type}: ${name}`)
        //   }
        // })

        // fill completion data
        this.formData.map(async item => {
          if (item.parent === 'completion') {
            this.getCompletionForm[item.fields] === true ? form.getCheckBox(item.id).check() : form.getCheckBox(item.id).uncheck()
          } else if (item.parent === 'payment') {
            this.getPaymentData && this.getPaymentData[item.fields] === item.value ? form.getCheckBox(item.id).check() : form.getCheckBox(item.id).uncheck()
          } else if (item.parent === 'personal_data') {
            if (item.type === 'text') {
              form.getTextField(item.id).setText(this.getPersonalData[item.fields])
            } else if (item.type === 'file' && pictImage[item.fields]) {
              form.getButton(item.id).setImage(pictImage[item.fields])
            } else if (item.type === 'date') {
              form.getDropdown(item.id).select(moment(new Date(this.getPersonalData[item.fields]*1000)).locale('id').format('DD'))
            } else if (item.type === 'month') {
              form.getDropdown(item.id).select(moment(new Date(this.getPersonalData[item.fields]*1000)).locale('id').format('MMMM'))
            } else if (item.type === 'year') {
              form.getTextField(item.id).setText(moment(new Date(this.getPersonalData[item.fields]*1000)).locale('id').format('YYYY'))
              // form.getDropdown(item.id).select(moment(new Date(this.getPersonalData[item.fields]*1000)).locale('id').format('YYYY'))
            } else if (item.type === 'radio') {
              item.options.map(itm => {
                if (itm.value === this.getPersonalData[item.fields]) {
                  const value = form.getRadioGroup(item.id).acroField.getOnValues()
                  form.getRadioGroup(item.id).acroField.setValue(value[itm.select])
                }
              })
            }
          } else if (item.parent === 'agreement_data') {
            if (item.type === 'file' && pictImage[item.fields]) {
              form.getButton(item.id).setImage(pictImage[item.fields])
            } else if (item.type === 'text') {
              form.getTextField(item.id).setText(this.getAgreementData[item.fields])
            } else if (item.type === 'date') {
              this.getAgreementData[item.fields] > 0 && form.getDropdown(item.id).select(moment(new Date(this.getAgreementData[item.fields]*1000)).locale('id').format('DD'))
            } else if (item.type === 'month') {
              this.getAgreementData[item.fields] > 0 && form.getDropdown(item.id).select(moment(new Date(this.getAgreementData[item.fields]*1000)).locale('id').format('MMMM'))
            } else if (item.type === 'year') {
              this.getAgreementData[item.fields] > 0 && form.getTextField(item.id).setText(moment(new Date(this.getAgreementData[item.fields]*1000)).locale('id').format('YYYY'))
            }
          } else if (item.parent === 'concentration') {
            item.option.map(itm => {
              if (item.byIndex) {
                if (itm.value === this.getConcentrationData) {
                  const value = form.getRadioGroup(item.id).acroField.getOnValues()
                  form.getRadioGroup(item.id).acroField.setValue(value[itm.select])
                }
              } else {
                if (itm.value === this.getConcentrationData) {
                  form.getRadioGroup(item.id).select(itm.select)
                }
              }
            })
          } else if (item.parent === 'family_data') {
            if (item.type === 'text') {
              form.getTextField(item.id).setText(this.getFamilyData[item.fields])
            } else {
              form.getDropdown(item.id).select(this.getFamilyData[item.fields])
            }
          } else if (item.parent === 'marriage_data') {
            if (item.type === 'text') {
              form.getTextField(item.id).setText(this.getMarriageData[item.fields])
            } else if (item.type === 'date') {
              this.getMarriageData[item.fields] > 0 && form.getDropdown(item.id).select(moment(new Date(this.getMarriageData[item.fields]*1000)).locale('id').format('DD'))
            } else if (item.type === 'month') {
              this.getMarriageData[item.fields] > 0 && form.getDropdown(item.id).select(moment(new Date(this.getMarriageData[item.fields]*1000)).locale('id').format('MMMM'))
            } else if (item.type === 'year') {
              this.getMarriageData[item.fields] > 0 && form.getDropdown(item.id).select(moment(new Date(this.getMarriageData[item.fields]*1000)).locale('id').format('YYYY'))
            } else if (item.type === 'radio') {
              item.options.map(itm => {
                if (itm.value === this.getMarriageData[item.fields]) {
                  const value = form.getRadioGroup(item.id).acroField.getOnValues()
                  form.getRadioGroup(item.id).acroField.setValue(value[itm.select])
                }
              })
            } else if (item.type === 'select') {
              item.option.map(itm => {
                if (itm === this.getMarriageData[item.fields]) {
                  form.getDropdown(item.id).select(itm)
                }
              })
            }
          } else if (item.parent === 'health_history_data') {
            if (item.type === 'text') {
              form.getTextField(item.id).setText(this.getHealthData[item.fields])
            } else if (item.type === 'radio') {
              item.options.map(itm => {
                if (itm.value === this.getHealthData[item.fields]) {
                  const value = form.getRadioGroup(item.id).acroField.getOnValues()
                  form.getRadioGroup(item.id).acroField.setValue(value[itm.select])
                }
              })
            }
          } else if (item.parent === 'skill_data') {
            if (item.type === 'text') {
              form.getTextField(item.id).setText(this.getSkillData[item.fields])
            } else if (item.type === 'select') {
              item.option.map(itm => {
                if (itm === this.getSkillData[item.fields]) {
                  form.getDropdown(item.id).select(itm)
                }
              })
            }
          } else if (item.parent === 'education_history_data') {
            if (item.type === 'text') {
              form.getTextField(item.id).setText(this.getEducationData[item.fields])
            } else if (item.type === 'select' && this.getEducationData[item.fields]) {
              form.getDropdown(item.id).select(this.getEducationData[item.fields])
            }
          } else if (item.parent === 'job_history_data') {
            if (item.type === 'text') {
              form.getTextField(item.id).setText(this.getJobData[item.fields])
            } else if (item.type === 'select' && this.getJobData[item.fields]) {
              form.getDropdown(item.id).select(this.getJobData[item.fields])
            }
          } else if (item.parent === 'service_history_data') {
            if (item.type === 'text') {
              form.getTextField(item.id).setText(this.getServiceData[item.fields])
            } else if (item.type === 'select' && this.getServiceData[item.fields]) {
              form.getDropdown(item.id).select(this.getServiceData[item.fields])
            }
          } else if (item.parent === 'church_member_data') {
            if (item.type === 'text') {
              form.getTextField(item.id).setText(this.getChurchData[item.fields])
            } else if (item.type === 'select' && this.getChurchData[item.fields]) {
              form.getDropdown(item.id).select(this.getChurchData[item.fields])
            }
          } else if (item.parent === 'financial_data') {
            item.options.map(itm => {
              if (itm.value === this.getFinancialData[item.fields]) {
                const value = form.getRadioGroup(item.id).acroField.getOnValues()
                form.getRadioGroup(item.id).acroField.setValue(value[itm.select])
              }
            })
          } else if (item.parent === 'personal_spiritual_data') {
            if (item.type === 'radio') {
              item.options.map(itm => {
                if (itm.value === this.getPersonalSpiritualData[item.fields]) {
                  const value = form.getRadioGroup(item.id).acroField.getOnValues()
                  form.getRadioGroup(item.id).acroField.setValue(value[itm.select])
                }
              })
            } else if (item.type === 'select' && this.getPersonalSpiritualData[item.fields]) {
              form.getDropdown(item.id).select(`${this.getPersonalSpiritualData[item.fields]}`)
            } else {
              form.getTextField(item.id).setText(`${this.getPersonalSpiritualData[item.fields]}`)
            }
          } else if (item.parent === 'personal_struggle_data') {
            if (item.type === 'checkBox') {
              this.getPersonalStrugleData[item.fields] === true ? form.getCheckBox(item.id).check() : form.getCheckBox(item.id).uncheck()
            } else {
              form.getTextField(item.id).setText(`${this.getPersonalStrugleData[item.fields]}`)
            }
          } else if (item.parent === 'other_data') {
            if (item.type === 'radio') {
              item.options.map(itm => {
                if (itm.value === this.getOtherData[item.fields]) {
                  const value = form.getRadioGroup(item.id).acroField.getOnValues()
                  form.getRadioGroup(item.id).acroField.setValue(value[itm.select])
                }
              })
            } else if (item.type === 'text') {
              form.getTextField(item.id).setText(this.getOtherData[item.fields])
            } else if (item.type === 'checkBox') {
              this.getOtherData[item.fields] === true ? form.getCheckBox(item.id).check() : form.getCheckBox(item.id).uncheck()
            }
          } else if (item.parent === 'recommendation_form') {
            form.getTextField(item.id).setText(this.getRecommendationData[item.fields])
          } else if (item.parent === 'repentance_story') {
            form.getTextField(item.id).setText(this.getRepetance)
          } else if (item.parent === 'calling_faith_story') {
            form.getTextField(item.id).setText(this.getCallingFaithStory)
          } else if (item.parent === 'motivation_story') {
            form.getTextField(item.id).setText(this.getMotivationStory)
          } else if (item.parent === 'univ_data') {
            if (item.type === 'text') {
              form.getTextField(item.id).setText(this.getUnivData[item.fields])
            } else if (item.type === 'select' && this.getUnivData[item.fields]) {
              form.getDropdown(item.id).select(this.getUnivData[item.fields])
            }
          }
        })
      }
      const pdfBytes = await pdfDoc.save()
      this.source = pdfBytes.buffer
    }
  },
  methods: {
    getConfigData () {
      const prodi = get(this.$store.state, 'studentDetail.program_study', 'sth')
      if (prodi === 'sth') {
        this.formData = sth
        this.url = 'https://webadmisi-sttaa.sgp1.digitaloceanspaces.com/registration-form/V2/form-sth.pdf'
      } else if (prodi === 'mth') {
        this.formData = mth
        this.url = 'https://webadmisi-sttaa.sgp1.digitaloceanspaces.com/registration-form/V2/form-mth.pdf'
      } else if (prodi === 'mthu') {
        this.formData = mthu
        this.url = 'https://webadmisi-sttaa.sgp1.digitaloceanspaces.com/registration-form/V2/form-mthu.pdf'
      } else if (prodi === 'mmin') {
        this.formData = mmin
        this.url = 'https://webadmisi-sttaa.sgp1.digitaloceanspaces.com/registration-form/V2/form-mmin.pdf'
      } else if (prodi === 'mdiv') {
        this.formData = mdiv
        this.url = 'https://webadmisi-sttaa.sgp1.digitaloceanspaces.com/registration-form/form-registration-mdiv.pdf'
      } else if (prodi === 'sert') {
        this.formData = sert
        this.url = 'https://webadmisi-sttaa.sgp1.digitaloceanspaces.com/registration-form/V2/form-sert.pdf'
      }
    },
    async fillForm () {
      downloadjs(this.source, "form-registration.pdf", "application/pdf");
    }
  }
}
</script>

<style lang="scss" scoped>
.pdf-content {
  .pdfTemp {
    height: 800px;
    ::v-deep .toolbar {
      z-index: 0;
    }
  }
}
</style>